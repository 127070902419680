import React from 'react'
import HTMLText from 'components/HTMLText'
import Appear from 'components/Appear'

type Props = {
  text: String,
  datetime: String,
  title: String,
  className: String,
}

const Event = ({
  text,
  datetime,
  title,
  className,

}: Props) =>{

  return (
    <>
      <Appear className={`mx-6px p-30px md:p-6 text-left bg-white rounded-xl relative ${className}`}>
        {/* <div className={`p-30px md:p-6 text-left bg-white rounded-xl relative ${className}`}> */}
          <h1 className="mb-6">{title}</h1>
          <HTMLText className="mb-8 font-light" text={text} />
          <p className="absolute bottom-0 pb-6 font-light align-bottom">{datetime}</p>
        {/* </div> */}
      </Appear>
    </>

  )
}

export default Event