// @flow

import React from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'
import Image from 'components/Image'
import type { Image as ImageType } from 'types/'

type Props = {
  image: ImageType,
  text: string,
  inverted?: number,
}

const DetailInlineImageArticle = ({
  image,
  text,
  inverted,
 }: Props) => {
  return (
    <div className="text-white">
      <Container gridType="grid" className="relative pt-5 pb-16 md:pb-24">
        <div className={`col-span-4 ${inverted === 1 ? "md:col-end-12" : "md:col-start-4"}`}>
          <Appear>
            <Image image={image} alt={text} transparency={false} />
          </Appear>
        </div>
        <div className={`${inverted === 1 ? "md:col-start-4 col-span-4 md:row-start-1 text-right" : "md:pl-5 md:col-end-13 col-span-4 md:col-span-5"} pr-4 md:pr-2 max-w-xs sm:max-w-none text-xl md:text-3xl leading-tight text-purple xl:text-3xl-vw`}>
          <Appear>
            <p>{text}</p>
          </Appear>
        </div>
      </Container>
    </div>
  )
}

export default DetailInlineImageArticle