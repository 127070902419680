import React, { useEffect, useState } from 'react'
import HTMLText from "components/HTMLText"
import type { Image as ImageType } from 'types/'
import Appear from 'components/Appear'

type Props = {
  name: String,
  role: String,
  image: ImageType,
  description?: String,
  link?: String,
}

const Speaker = ({
  name,
  role,
  image,
  description = false,
  link = false,
}: Props) =>{
  const [bgImgUrl, setBgImgUrl] = useState()
  const [fullImage, setFullImage] = useState(false)

  function checkImage(imageSrc, image) {
    const img = new window.Image()
    img.onload = () => {
      setBgImgUrl(imageSrc)
    }
    img.onerror = () => {
      setFullImage(true)
      setBgImgUrl(`${image.dir}${image.name.normal}.${image.extension}`)
    }
    img.src = imageSrc
}

  useEffect(() => {
    const width = window.innerWidth
    let size = 450
    switch (true) {
      case (width > 1024):
         size = 800
        break
      case (width > 1440):
         size = 1200
        break
      case (width > 1920):
         size = 2400
        break
      default:
        break
    }

    checkImage(`${image.dir}${image.name.normal}-${size}.${image.extension}`, image)
  }, [])

  return(
    <>
    <Appear className="text-left bg-white md:col-span-4 lg:col-span-2 min-h-18 h-310 rounded-2xltext-left rounded-2xl">
      <div className={`h-80vw xs:h-40vw md:h-30vw lg:h-20vw xxl:h-384px rounded-t-2xl ${fullImage ? 'bg-center' : 'bg-top'}`} style={{backgroundImage:`url(${bgImgUrl})`, backgroundPosition: "top center", backgroundRepeat: "no-repeat", backgroundSize: 'cover'}}></div>

      <h1 className={`px-6 pt-6 ${description && 'font-bold'}`}>{name}</h1>
      <p className="px-6 pb-6 font-light align-bottom">{role}</p>

      {description &&
        <HTMLText
          className="px-6 pb-6 m-auto font-mono font-light leading-tight text-left text-black normal-case text-normal md:max-w-44"
          text={description}
        />
      }
      {link &&
        <a
          href={link}
          className="block px-6 pb-6 text-sm font-medium text-left break-all cursor-pointer"
        >
          {link}
        </a>
      }
    </Appear>
    </>
  )
}

export default Speaker