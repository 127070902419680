import React from 'react'
import Menu2021 from 'components/Menu2021'
import parseVideo from '../lib/parseVideo'

type Props = {
  href: String,
  bgcolor: String, 
  videoId: String,
  menuItems?: Array,
  editionBGColor: String,
}

const VideoBlock = ({
  bgcolor,
  videoId,
  menuItems,
  editionBGColor, 
}: Props)=>{
  const { id } = parseVideo(videoId)
  
  return (
    <> {id &&
      <div style={{backgroundColor: `#${bgcolor}`}}>
        {!menuItems ? '' :
          <Menu2021 menuItems={menuItems} editionBGColor={editionBGColor} color="white" parent="video"/>
        }
        <div className={`relative m-auto lg:max-w-full ${menuItems? 'px-12 pb-12 pt-0 md:px-160px md:pb-24 md:pt-0' : 'p-12 md:p-160px'}`} style={{backgroundColor: `#${bgcolor}`}} >
          <div className="relative" style={{ paddingBottom: "53.60%", paddingTop: 25, height: 0,}}> 
            <iframe className="absolute top-0 left-0 w-full h-full"
              src={`https://www.youtube.com/embed/${id}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            frameBorder="0"/>
          </div>
        </div>
      </div>
    } </>
  )
}

export default VideoBlock
