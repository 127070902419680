// @flow

import React, { useEffect, useRef, useState } from 'react'
import type { Ref, Image as ImageType } from 'types/'
import WebP from '../lib/WebP'
import addObserver from '../lib/addObserver'
import throttle from '../lib/throttle'

type Props = {
  className?: string,
  image: ImageType,
  alt: string,
  callback?: any,
  zoomIn?: boolean,
  force?: boolean,
}

const Image = ({ className = '', image, alt, callback = false, zoomIn = false, force = false }: Props) => {
  const mainRef: Ref = useRef(null)
  const [imageSrc, setImageSrc] = useState(false)
  const [showLazy, setShowLazy] = useState(false)
  const [showImage, setShowImage] = useState(false)
  let lastSize = -1

  const wp = new WebP()
  const loadImage = () => {
    let imageToLoadUrl = `${image.dir}${image.name.normal}.${image.extension}`
    let newSize = 0
    const elementWidth = mainRef.current.offsetWidth * (window.devicePixelRatio || 1)
    wp.isSupported(supported => {
      if (image.webp) {
        imageToLoadUrl = `${image.dir}${image.name.normal}-${image.sizes[0]}.${
          supported ? "webp" : image.extension
        }`
        image.sizes.sort((a, b) => a - b).forEach((size, i) => {
          if (elementWidth > size) {
            const s =
              typeof image.sizes[i + 1] !== "undefined"
                ? image.sizes[i + 1]
                : size
            imageToLoadUrl = `${image.dir}${image.name.normal}-${s}.${
              supported ? "webp" : image.extension
            }`
            newSize = s
          }
        })
      }
      if (imageSrc !== imageToLoadUrl && newSize > lastSize) {
        const imageToLoad = new window.Image()
        imageToLoad.onload = () => {
          setImageSrc(imageToLoadUrl)
          lastSize = newSize
        }
        imageToLoad.src = imageToLoadUrl
      }
    })
  }

  const throttleResize = throttle(loadImage, 1000)

  useEffect(() => {
    if (force) {
      setShowLazy(true)
      loadImage()
      setShowImage(true)
      if (callback) callback()
    } else {
      addObserver(mainRef.current, '300%', 0, () => {
        setShowLazy(true)
      })
      addObserver(mainRef.current, '200%', 0, () => {
        loadImage()
      })
      addObserver(mainRef.current, '0%', 0, () => {
        setShowImage(true)
        if (callback) callback()
      })
    }
    window.addEventListener('resize', throttleResize)
    return () => {
      window.removeEventListener('resize', throttleResize)
    }
  }, [])

  return (
    <div
      ref={mainRef}
      className={`${className ? className : ''} overflow-hidden relative  transition duration-normal ${imageSrc && showImage ? 'opacity-100' : 'opacity-0'}`}
    >
      {showLazy && 
      <picture className={`${image.extension !== 'png' ? '' : 'invisible'}`}>
        {image.webp && (
          <source
            srcSet={`${image.dir}${image.name.lazy}.webp`}
            type="image/webp"
          />
        )}
        <img
          className="w-full"
          src={`${image.dir}${image.name.lazy}.${image.extension}`}
          alt={alt}
        />
      </picture>}
      {imageSrc !== false && (
        <img
          className={`absolute top-0 left-0 h-full w-full ${zoomIn ? 'transition duration-normal transform hover:scale-105' : ''}`}
          src={imageSrc}
          alt={alt}
        />
      )}
    </div>
  )
}

export default Image
