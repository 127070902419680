import React, { useEffect, useState } from 'react'
import Appear from 'components/Appear'
import HTMLText from 'components/HTMLText'
import Speaker from 'components/Speaker'
import Container from 'components/Container'

type Props = {
  title: String,
  text: String,
  editionBGColor: String,
  speakers: Array,
  image: ImageType,
}

const Speakers2021 = ({
  title,
  text,
  editionBGColor,
  speakers,
  image,
}: Props) => {
  const [bgmask, setBgmask] = useState()

  useEffect(()=>{
    const baseURL = window.location.origin
    setBgmask(baseURL + image.dir + image.name.normal + '.' + image.extension)
  }, [])


  return (
    <>
      <div className="relative col-span-4 row-start-2 px-6 py-4 m-auto font-mono text-center uppercase bg-left-top bg-cover speakers md:pt-20 md:pb-32 md:px-0 md:row-start-3 md:col-span-8 md:col-start-3 -z-10" style={{backgroundColor: `#${editionBGColor}`}}>
        <div className="absolute bottom-0 right-0 mb-40 -mr-64 bg-center bg-cover" style={{
          height: '100%',
          maxHeight: '1000px',
          maxWidth: '1000px',
          width: '70%',
          backgroundImage: `url(${bgmask})`,
        }}></div>
        <Appear>
          <h1 className="mx-auto mt-2 mb-8 font-mono text-4xl text-center md:mt-8 md:mb-4 md:text-4.5xl">{title}</h1>
        </Appear>
        <Appear>
          <HTMLText className="m-auto font-mono text-base font-light leading-tight text-center text-black uppercase md:max-w-45 md:text-xl" text={text} />
        </Appear>
        <Container className="lg:max-w-full">
          <div className="grid grid-cols-1 gap-8 mt-20 xs:grid-cols-2 md:grid-cols-8">
            {speakers && speakers.map((speaker, index)=>(
              <Speaker key={index} name={speaker.name} role={speaker.role} image={speaker.image}/>
            ))}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Speakers2021