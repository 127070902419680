import React, { useEffect, useState } from "react"
import Appear from "components/Appear"
import HTMLText from "components/HTMLText"
import Menu2021 from 'components/Menu2021'
import Speaker from 'components/Speaker'
import type { Image as ImageType } from "types/"

type Props = {
  title: String,
  text: String,
  subtitle: String,
  text2: String,
  ctaTitle: String,
  ctaUrl: String,
  speakers: Array,
  image: ImageType,
  menuItems?: Array,
  editionBGColor: String,
}

const Talk2021 = ({
  title,
  text,
  subtitle,
  text2,
  ctaTitle,
  ctaUrl,
  speakers,
  menuItems,
  image,
  editionBGColor,
}: Props) => {
  const [bgmask, setBgmask] = useState()

  useEffect(() => {
    const baseURL = window.location.origin
    setBgmask(baseURL + image.dir + image.name.normal + "." + image.extension)
  }, [])

  return (
    <>
      <div
        className="relative col-span-4 row-start-2 px-6 pt-4 m-auto font-mono text-center uppercase md:pt-12 md:pb-16 md:px-0 md:row-start-3 md:col-span-8 md:col-start-3"
        style={{ backgroundColor: `#9BCEFD` }}
      >
        {bgmask && (
          <div
            className="absolute top-0 left-0 w-1/2 bg-center bg-cover"
            style={{
              height: '90vw',
              width: '70%',
              backgroundImage: `url(${bgmask})`,
            }}
          ></div>
        )}
        {!menuItems ? '' :
          <Menu2021 menuItems={menuItems} editionBGColor={editionBGColor} color="black" parent="video"/>
        }
        {title &&
          <Appear>
            <h1 className="leading-tight mx-auto mt-16 mb-8 font-mono text-2xl text-black uppercase text-center md:max-w-35 md:mt-16 md:mb-3.5 md:text-4.5xl">
              {title}
            </h1>
          </Appear>
        }
        {text &&
          <Appear>
            <HTMLText
              className="mx-auto mb-6 font-mono font-light leading-tight text-center text-black uppercase text-normal md:max-w-45 md:text-xl"
              text={text}
            />
          </Appear>
        }
        {subtitle && 
          <Appear>
            <p className="m-auto mb-6 font-mono font-normal leading-tight text-center text-black uppercase text-normal md:max-w-44 md:text-xl">
              {subtitle}
            </p>
          </Appear>
        }
        {text2 &&
          <Appear>
            <HTMLText
              className="m-auto font-mono font-light leading-tight text-center text-black uppercase text-normal md:max-w-45 md:text-xl"
              text={text2}
            />
          </Appear>
        }
        {ctaTitle && 
          <Appear className="flex items-center justify-center">
            <a
              className="px-8 py-3 mt-12 mb-16 text-base font-light text-center bg-white rounded-full cursor-pointer"
              href={ctaUrl}
            >
              {ctaTitle}
            </a>
          </Appear>
        }
        {speakers &&
          <div className="px-4 pt-3 m-auto md:px-12 lg:max-w-full">
            <Appear>
              <div className={`${(speakers.length === 1) && 'max-w-24'} ${(speakers.length === 2) && 'max-w-38'} ${(speakers.length === 3) && 'max-w-56'}  ${speakers.length < 4 ? 'flex justify-center m-auto' : 'grid'} items-start grid-cols-1 gap-8 mt-6 sm:grid-cols-2 lg:grid-cols-8`}>
                {speakers && speakers.map((speaker, index) => (
                  <Speaker
                    key={index}
                    name={speaker.name}
                    role={speaker.role}
                    image={speaker.image}
                    description={speaker.description}
                    link={speaker.link}
                  />
                ))}
              </div>
            </Appear>
          </div>
        }
      </div>
    </>
  )
}

export default Talk2021
