// @flow

import React, { useState } from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'
import Image from 'components/Image'
import type { Image as ImageType } from 'types/'

type LinkType = {
  id: string,
  title: string,
}

type Props = {
  pageName: string,
  image: ImageType,
  cta?: string,
  href?: string,
  videoId?: string,
  links: Array<LinkType>
}

const DetailBigImage = ({
  pageName,
  image,
  cta,
  href,
  videoId,
  links,
 }: Props) => {
  if ((href && videoId) || image.dir !== "") {
    return (
      <div className={`${pageName === "talkDetail" ? "bg-black text-white" : ""}`}>
        <Container gridType={`${pageName === "talkDetail" ? "subgrid" : "grid"}`} className="relative">
          <div className={`${pageName === "talkDetail" ? "col-span-4 md:col-span-8 col-start-1" : "col-span-4 md:col-span-11 md:col-start-2"} mb-16 md:mb-24`}>
            <Appear>
              <div className="relative">
                {href && videoId ? (
                  <div
                    className="relative"
                    style={{
                      paddingBottom: "56.25%",
                      paddingTop: 25,
                      height: 0,
                    }}
                  >
                    <iframe
                      className="absolute top-0 left-0 w-full h-full"
                      src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                      frameBorder="0"
                      allow="accelerometer;
                        autoplay;
                        encrypted-media;
                        gyroscope;
                        picture-in-picture"
                    />
                  </div>
                ) : (
                  <Image image={image} alt="" transparency={false} />
                )}
                {/* {cta && href && (
                  <div className={`absolute inset-0 z-20 flex items-center justify-center transition-opacity duration-150 ease-linear ${isPlaying ? "opacity-0 pointer-events-none" : "opacity-100"}`} style={{ background: "rgba(0, 0, 0, 0.8)" }}>
                    <button type="button" onClick={() => {setIsPlaying(true)}}>
                      <div className="px-8 py-3 text-sm text-white rounded md:px-10 md:text-xl xl:text-xl-vw bg-purple">
                        {cta}
                      </div>
                    </button>
                  </div>
                )} */}
              </div>
            </Appear>
            {/* <div className="flex justify-start mt-3 md:mt-1 item-center">
              {href && videoId && links && links.map(link => {
                if (link.id === "2") return (
                  <Appear key={link.title}>
                    <a
                      href={href || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mr-5 text-sm underline cursor-pointer hover:no-underline md:mr-8 md:text-xl xl:text-xl-vw"
                    >
                      {link.title}
                    </a>
                  </Appear>
                )
                return null
                return (
                  <Appear key={link.title}>
                    <span
                      onClick={() => console.log("debería copiar el href al portapapeles")}
                      className="mr-5 text-sm underline cursor-pointer hover:no-underline md:mr-8 md:text-xl xl:text-xl-vw"
                    >
                      {link.title}
                    </span>
                  </Appear>
                )
              })}
            </div> */}
          </div>
        </Container>
      </div>
    )
  }

  return null
}

export default DetailBigImage