import React from 'react'
import { Link } from 'gatsby'

type Props = {
  title: String,
  url?: String,
  callback?: String,
  className: String,
}

const Button = ({
  title,
  url,
  callback,
  className,
}: Props) =>{

  return (
    <>
      <div className={className}>
        <a className="justify-between px-6 pt-4 pb-3 m-auto text-base font-light bg-white rounded-full cursor-pointer md:text-xs lg:text-base lg:px-6 xl:px-8" href={(url)} onClick={(callback)}>{title}</a>
      </div>
    </>
  )
}

export default Button