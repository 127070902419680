// @flow

import React, { useState, useRef, useEffect } from 'react'
import type { Ref } from 'types/'

type Props = {
	className?: string,
	rootMargin?: string,
	threshold?: number,
	delay?: number,
	withImage?: boolean,
	callback?: () => void,
	inline?: boolean,
	children: React$Node,
}

const Appear = ({ className = "", rootMargin = '0px', threshold = 0.25, delay = 0, withImage = false, callback = () => {}, inline = false, children }: Props) => {

	const mainRef: Ref = useRef(null)
	const [intersecting, setIntersecting] = useState(false)

	useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
				if (entry.isIntersecting) {
					setTimeout(() => {
						if (callback) {
							callback()
						}
						setIntersecting(true)
					}, delay)
					observer.unobserve(mainRef.current)
				}
      },
      {
        rootMargin,
        threshold: [threshold],
      }
    )
    if (mainRef.current) {
      observer.observe(mainRef.current)
    }
  }, [])

  return (
		<div ref={mainRef} className={`${inline ? 'inline' : ''} ${className ? className : ""} duration-normal transition transition-normal ease-out transform ${intersecting ? 'opacity-100 translate-y-0' : `opacity-0 translate-y-${withImage ? '16' : '8'}`}`}>
      {children}
    </div>
  )
}

export default Appear