// @flow

import React, { useEffect } from 'react'
import ModuleIterator from 'components/ModuleIterator'
import Layout from './Layout'
import { Helmet } from 'react-helmet'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import type { Meta, Module } from 'types/'

type Props = {
  meta: Meta,
  structure: Array<Module>,
  literals: {},
  media: {},
  language: string,
  edition?: string,
  bgcolor?: string
}

const Page = ({ meta, structure, language, edition, bgcolor }: Props) => {
  const {
    title,
    description,
    keywords,
    favicon,
    canonical,
    author,
    image,
  }: Meta = meta
  
  useEffect(() => {
    const script1 = document.createElement('script')
    script1.innerHTML = `function OptanonWrapper() {window.dataLayer.push({event:'OneTrustGroupsUpdated'})}`
    document.body.appendChild(script1)
  }, [])

  return (
    <>
      {process.env.ROBOTS === 'NO' && <GatsbySeo noindex={true} dangerouslySetAllPagesToNoIndex={true} />}
      <Helmet title="es">
        <html lang={language} />
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        {process.env.ROBOTS && <meta name="robots" content="noindex" />}
        {process.env.ROBOTS && <meta name="googlebot" content="noindex" />}
        <meta name="language" content={language} />
        <meta property="og:image" content={image} />
        <link rel="icon" type="image/png" href={favicon} />
        <meta
          name="author"
          content={author || 'Dallonses, hello@dallonses.com'}
        />
        <meta name="canonical" content={canonical} />
      </Helmet>
      <Layout>
        <div className="relative z-10 overflow-hidden font-primary" style={{ backgroundColor: (bgcolor) ? `#${bgcolor}` : ''  }}>
          <ModuleIterator modules={structure} edition={edition} bgcolor={bgcolor} />
        </div>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script="d4335839-c03a-4381-b121-88dc5cd32f14" ></script>
      </Layout>
    </>
  )
}

export default Page
