import React from 'react'

const SvgYoutubeLogo = () => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" xmlSpace="preserve" width="25px">
<path d="M75.1,19.4c-16.2-2.7-32.8-2.7-49,0c-9.3,1.9-15.1,7.9-15.1,15.7v27.8c0,7.9,5.8,13.8,15.2,15.9c8.1,1.4,16.2,2,24.4,2.1
	c8.2,0,16.4-0.7,24.5-2c9.2-1.7,15.1-7.9,15.1-15.9V35.2C90.4,27.3,84.4,21.1,75.1,19.4z M63.6,50.9L43.8,62.8
	c-0.3,0.2-0.7,0.3-1,0.3c-0.3,0-0.7-0.1-1-0.2c-0.6-0.4-1-1.1-1-1.8V37.2c0-0.7,0.4-1.4,1-1.7c0.6-0.3,1.4-0.3,2,0l19.8,11.9
	c0.9,0.6,1.2,1.9,0.6,2.8c-0.2,0.2-0.4,0.5-0.6,0.6V50.9z"/>
</svg>
)

export default SvgYoutubeLogo