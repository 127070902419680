// @flow

import React from 'react'
import ReactMarkdownWithHtml from 'react-markdown/with-html'

type Props = {
  className?: string,
  text: string,
}

const HTMLText = ({ className = "", text }: Props) => {
  let parsedText = text
  if (parsedText) {
    parsedText = parsedText.replace(/[ ]*\n/g, "<br />\n")
  }
  return (
    <ReactMarkdownWithHtml
    className={className}
    source={parsedText}
    escapeHtml={false}
  />
  )
}

export default HTMLText
