// @flow

import React from 'react'
import type { Image as ImageType } from 'types/'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import Appear from 'components/Appear'
import Image from 'components/Image'


type NetworkType = {
  title: string,
  link: string,
  image: any,
}

type SourceItemsType = {
  title: string,
  link: string,
}

type SourceObject = {
  title: string,
  items: Array<SourceItemsType>,
}

type Props = {
  image: ImageType,
  name: string,
  bio: string,
  link: string,
  description: string,
  single?: boolean,
  networks?: Array<NetworkType>,
  sources?: SourceObject,
}

const DetailTalkSpeaker = ({
  image,
  name,
  bio,
  link,
  description,
  networks,
  sources,
  single,
 }: Props) => {
  return (
    <>
      <Appear>
        <div className="w-full h-px mb-8 bg-white md:hidden" />
      </Appear>
      <div className={`pt-12 mb-16 text-base leading-snug md:mb-20 md:pt-0 ${single ? "md:sticky" : ""} xl:text-base-vw top-4`}>
        <Appear>
          <Image image={image} alt={name} transparency={false} />
        </Appear>
        <Appear>
          <h3 className="max-w-xs mt-8 mb-4 text-xl font-bold leading-tight sm:max-w-none md:my-4 text-purple xl:text-xl-vw">{name}</h3>
        </Appear>
        <Appear>
          <p className="max-w-xs sm:max-w-none ">{bio}</p>
        </Appear>
        {link && (
          <Appear>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink hover:underline"
            >
              {link}
            </a>
          </Appear>
        )}
        {description && (
          <Appear>
            <ReactMarkdownWithHtml className="mt-8 markdown">
              {description}
            </ReactMarkdownWithHtml>
          </Appear>
        )}
        {networks.length > 0 && (
          <Appear>
            <div className="flex flex-wrap items-start my-8 -mx-2 md:my-10 justfy-start">
                {networks.map(network => (
                  <a
                    key={network.title}
                    href={network.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-8 h-8 p-2 mr-2"
                  >
                    <img src={network.image.file} alt={network.title} />
                  </a>
                ))}
            </div>
          </Appear>
        )}
        {typeof sources !== "undefined" && typeof sources.items !== "undefined" && sources.items.length > 0 && (
          <Appear>
            <span className="block">{sources.title}</span>
            <ul>
              {sources.items.map(source => (
                <li key={source.title} className="">
                  <a
                    key={source.title}
                    href={source.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-pink hover:underline"
                  >
                    {source.title}
                  </a>
                </li>
              ))}
            </ul>
          </Appear>
        )}
      </div>
    </>
  )
}

export default DetailTalkSpeaker