// @flow

import React from 'react'
import { Link } from 'gatsby'
import Appear from 'components/Appear'
import Image from 'components/Image'
import type { Image as ImageType } from 'types/'

type SpeakerType = {
  name: string,
  role: string,
}

type Props = {
  isLink?: string,
  type: string,
  href: string,
  title: string,
  description?: string,
  cta?: string,
  image: ImageType,
  speakers?: Array<SpeakerType>
}

const GridItem = ({
  isLink,
  type,
  href,
  title,
  description,
  image,
  speakers,
  cta,
 }: Props) => {
  const hasTitle = typeof title !== "undefined" && title !== ""
  const hasDescription = typeof description !== "undefined" && description !== ""
  const isALink = isLink === "1"
  const hasImage = image.dir !== ""

  const content = (
    <>
      <Appear>
        {type === "talks" ? (
          <div className="w-full" style={{ paddingBottom: "100%" }}>
            {hasImage ? (
              <div className="absolute inset-0">
                <Image image={image} alt={title} transparency={false} className="h-full" imgClassName="h-full object-cover" />
              </div>
            ) : (
              <div className="absolute inset-0 bg-gray" />
            )}
          </div>
        ) : (
          <div className="w-full" style={{ paddingBottom: "75%" }}>
            {hasImage ? (
              <div className="absolute inset-0">
                <Image image={image} alt={title} transparency={false} className="h-full" imgClassName="h-full object-cover" />
              </div>
            ) : (
              <div className="absolute inset-0 bg-gray" />
            )}
          </div>
        )}
      </Appear>
      <Appear>
        <h6 className={`group-hover:underline pt-6 pb-3 md:my-4 max-w-md sm:max-w-full text-xl md:text-2xl leading-tight xl:text-2xl-vw ${type === "articles" && hasTitle && hasDescription ? "font-bold" : ""}`}>{title}</h6>
      </Appear>
      {description && (
        <Appear>
          <p className="pb-4 text-2xl leading-tight xl:text-2xl-vw group-hover:underline">{description}</p>
        </Appear>
      )}
      <Appear>
        {speakers && speakers.map((speaker, index) => {
          if (speakers.length > 1) {
            const last = index + 1 === speakers.length
            return (
              <span key={index}>{speaker.name}{last ? " " : ", "}</span>
            )
          }

          return (
            <React.Fragment key={index}>
              <span className="block text-sm md:text-base xl:text-base-vw">{speaker.name}</span>
              <span className="block text-sm md:text-base xl:text-base-vw">{speaker.role}</span>
            </React.Fragment>
          )
        })}
      </Appear>
      {cta && (
        <Appear>
          <div className="pt-2 md:mt-0">
            <span className="text-xl leading-tight underline text-pink xl:text-xl-vw">
              {cta}
            </span>
          </div>
        </Appear>
      )}
    </>
  )

  if (isALink) return (
    <div className="mb-8 group">
      <a href={href} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    </div>
  )

  return (
    <div className="mb-8 group">
      <Link to={href}>
        {content}
      </Link>
    </div>
  )
}

export default GridItem