// @flow

import React from 'react'

type Props = {
  color: string,
}

const SvgArrowDown = ({ color }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30"><path fill={color} fillRule="evenodd" d="M5.857 0v17.622H0l6.959 12.053 6.958-12.053H8.06V2.202H40V0z"/></svg>
  )
}

export default SvgArrowDown