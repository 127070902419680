import React from 'react'
import Image from 'components/Image'
import Appear from 'components/Appear'
import type { Image as ImageType } from 'types/'


type Props = {
  title: String,
  image: ImageType,
  speakers: String,
  href:  String,
}

const Items = ({
  title,
  image,
  speakers,
  href,

}:Props) =>{

  return (
    <>
    <Appear className="h-full pb-6 text-left bg-white outline-none cursor-pointer min-h-18 md:col-span-2 rounded-2xl">
      <a href={href} className="h-full pb-6 text-left bg-white outline-none cursor-pointer min-h-18 md:col-span-2 rounded-2xl">
          <Image className="rounded-t-2xl" image={image} /> 
          <h1 className="px-6 py-4">{title}</h1>
          {speakers.map((speaker,index) => (
            <p key={index} className="px-6 font-light">{speaker.name}, {speaker.role}</p>
          ))}
      </a>
    </Appear>
    </>
  )
}

export default Items
