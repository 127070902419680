// @flow

import React from 'react'
import Page from 'components/Page'
import type { Module, Meta } from 'types/'
// import staticData from '../data/index'
// import processData from '../data/processData'

type DefaultTemplateContext = {
  meta: Meta,
  structure: Array<Module>,
  language: string,
  literals: {},
  media: {},
  edition: String,
  bgcolor: String
}

const DefaultTemplate = ({
  pageContext
}: {
  pageContext: DefaultTemplateContext
}) => {
  const { structure, meta, literals, language, media, edition, bgcolor } = pageContext
  return (
    <Page
      structure={structure}
      meta={meta}
      literals={literals}
      language={language}
      media={media}
      edition={edition}
      bgcolor={bgcolor}
    />
  )
}

export default DefaultTemplate
