// @flow

import React, { useState, useEffect } from 'react'
import type { Image as ImageType } from 'types/'
import { Link } from 'gatsby'
import Appear from 'components/Appear'
import Container from 'components/Container'
import SvgMeaninfulLogo from './SvgMeaninfulLogo'
import Button  from 'components/Button'

type Props = {
  parent?: String,
  event?: String,
  editionBGColor: String,
  menuItems?: Array,
  color?: String,
}

const Menu2021 = ({
  parent,
  event,
  menuItems,
  editionBGColor,
  color,
}: Props) => {

  const scrollTo = (anchor) => {
    const element = document.querySelector(`.${anchor}`)
    if (parent === 'video') window.location.href = `/2021#!${anchor}`
    if (element) {
      window.location.hash = `!${anchor}`
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const [clicked, setClicked] = useState (true)

  const handleClick = () =>{
    setClicked(!clicked)
  }

  let logoColor
  if (color) {
    switch (color) {
      case 'white':
        logoColor = '#fff'
        break
      default:
        logoColor = '#000'
        break
    }
  }

  return (
    <>
      <Container gridType="grid" className={`lg:max-w-full z-10 relative pr-6 pl-6 xs:pr-12 xs:pl-12 pt-12 ${(parent === 'video') ? 'pb-12 md:pb-20' : 'pb-20-vh md:pb-32'}`} >
        <div className="col-span-1 pr-4 sm:col-span-2 md:col-span-2 md:pr-0 lg:-ml-12">
          <Appear>
            <Link to="/">
              <SvgMeaninfulLogo color={logoColor} />
            </Link>
          </Appear>
        </div>
        {menuItems ?
          <div className="grid grid-flow-col grid-cols-1 text-center sm:col-span-4 md:col-span-10 md:col-end-13">
            <div className="absolute top-0 left-0 flex items-center justify-center w-screen h-screen md:hidden">
              <Appear className="w-screen h-screen">
                {clicked ?
                 <div className="flex flex-col items-end justify-end mt-12 mr-12 cursor-pointer" onClick={handleClick}>
                    <span className="block w-6 m-0.5 bg-black border border-black"></span>
                    <span className="block w-6 m-0.5 bg-black border border-black"></span>
                    <span className="block w-6 m-0.5 bg-black border border-black"></span>
                  </div>
                :
                <div className="absolute top-0 bottom-0 left-0 right-0 w-screen uppercase cursor-pointer md:hidden" onClick={handleClick}>
                  <div className="flex items-start justify-end mr-12 mt-3.5">
                    <span className="z-10 block w-6 -mr-6 transform -rotate-45 bg-black border border-black"></span>
                    <span className="z-10 block w-6 transform rotate-45 bg-black border border-black"></span>
                  </div>
                  <div className="absolute top-0 flex flex-col items-center justify-center w-full h-full font-mono" style={{backgroundColor: `#${editionBGColor}`}}>
                    <Button className="mb-8" title={menuItems[0].ctaTitle} callback={() => scrollTo(menuItems[0].ctaUrl)}/>
                    <Button className="my-8" title={menuItems[1].ctaTitle} callback={() => scrollTo(menuItems[1].ctaUrl)}/>
                    <Button className="my-8" title={menuItems[2].ctaTitle} url={menuItems[2].ctaUrl}/>
                    <Button className="my-8" title={menuItems[3].ctaTitle} url={menuItems[3].ctaUrl}/>
                  </div>
                </div>
                }
              </Appear>
            </div>
            <Appear>
              <div className="justify-end hidden font-mono uppercase md:flex lg:mr-0">
                <Button className="mx-1 lg:mx-2 lg:ml-0" title={menuItems[0].ctaTitle} callback={() => scrollTo(menuItems[0].ctaUrl)}/>
                <Button className="mx-1 lg:mx-2" title={menuItems[1].ctaTitle} callback={() => scrollTo(menuItems[1].ctaUrl)}/>
                <Button className="mx-1 lg:mx-2" title={menuItems[2].ctaTitle} url={menuItems[2].ctaUrl}/>
                <Button className="mx-1 lg:mx-2 lg:mr-0" title={menuItems[3].ctaTitle} url={menuItems[3].ctaUrl}/>
              </div>
            </Appear>
          </div>
        :
          <div className="col-span-3 text-right sm:col-span-2 md:col-span-3 md:col-end-13">
            <Appear>
                <p className="font-mono">{event}</p>
            </Appear>
          </div>
        }
      </Container>
    </>
  )
}

export default Menu2021