import React, { useEffect, useState } from 'react'
import Appear from 'components/Appear'
import HTMLText from 'components/HTMLText'
import Container from 'components/Container'
import Items from 'components/Items'
import type { Image as ImageType } from 'types/'
import Menu2021 from 'components/Menu2021'

type Props = {
  title: String,
  text: String,
  editionBGColor: String,
  items: Array,
  image: ImageType,
  menuItems?: Array,
}

const Related2021Talks = ({
  title,
  text,
  editionBGColor,
  items,
  image,
  menuItems,
}: Props) => {

  const [bgmask, setBgmask] = useState()

  useEffect(()=>{
    const baseURL = window.location.origin
    setBgmask(baseURL + image.dir + image.name.normal + '.' + image.extension) 
  }, [])

  return (
    <>
     <div className="relative col-span-4 row-start-2 px-6 py-12 m-auto overflow-visible font-mono text-center uppercase bg-left-top bg-cover md:pt-12 md:pb-32 md:px-0 md:row-start-3 md:col-span-8 md:col-start-3"
     >
        {bgmask &&
          <div className="absolute top-0 left-0 bg-center bg-cover" style={{
            height: '100%',
            maxHeight: '1000px',
            maxWidth: '1000px',
            width: '70%',
            backgroundImage: `url(${bgmask})`,
          }}></div>
        }
        {!menuItems ? '' :
          <Menu2021 menuItems={menuItems} editionBGColor={editionBGColor} color="black" parent="video"/>
        }
        <Appear>
          <h1 className="mx-auto mt-2 mb-8 font-mono text-4xl text-center md:mt-0 md:mb-4 md:text-4.5xl">{title}</h1>
        </Appear>
        <Appear>
          <HTMLText className="m-auto font-mono font-light leading-tight text-black uppercase text-normal md:max-w-35 md:text-xl" text={text} />
        </Appear>
        {/* <Appear> */}
          <Container className="lg:max-w-full">
            <div className="grid items-start grid-cols-1 gap-8 mt-16 xs:grid-cols-2 md:grid-cols-8">
              {items.map((item, index)=>(
                <Items appear={true} key={index} title={item.title} image={item.image} speakers={item.speakers} href={item.href}/>
              ))}
            </div>
          </Container>
        {/* </Appear> */}
      </div>
    </>
  )
}

export default Related2021Talks