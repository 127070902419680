// @flow

import React from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'

type Props = {
  pageName: string,
  smallTitle: string,
  title: string,
  subTitle: string,
}

const DetailTitle = ({
  pageName,
  smallTitle,
  title,
  subTitle,
 }: Props) => {
  return (
    <div className={`${pageName === "talkDetail" ? "bg-black text-white" : ""}`}>
      <Container gridType={`${pageName === "talkDetail" ? "subgrid" : "grid"}`} className="relative pb-8 md:pb-10">
        <div className={`col-span-3 ${pageName === "talkDetail" ? "col-start-1" : "md:col-start-4"} row-start-1`}>
          <Appear>
            <span className="text-sm font-bold md:text-xl text-purple xl:text-xl-vw">— {smallTitle}</span>
          </Appear>
        </div>
        <div key="title-area" className={`${pageName === "talkDetail" ? "col-start-1" : "md:col-start-4"} max-w-xs sm:max-w-none col-span-4 md:col-span-7 md:row-start-2`}>
          <Appear>
            <h1 className="text-3xl leading-none md:text-5xl xl:text-5xl-vw">{title}</h1>
          </Appear>
        </div>
        <div key="subtitle-area" className={`col-span-4 md:col-span-8 ${pageName === "talkDetail" ? "col-start-1" : "md:col-start-4"} row-start-3 md:mt-2`}>
          <Appear>
            <h4 className={`${pageName === "talkDetail" ? "text-sm" : "text-xl max-w-xs sm:max-w-none"} leading-tight md:text-3xl xl:text-3xl-vw`}>{subTitle}</h4>
          </Appear>
        </div>
      </Container>
    </div>
  )
}

export default DetailTitle