import React, { useEffect, useState } from 'react'
import type { Image as ImageType } from 'types/'
import Appear from 'components/Appear'
import HTMLText from 'components/HTMLText'
import Button  from 'components/Button'
import Day from 'components/Day'


type Props = {
  title: String,
  text: String,
  editionBGColor: String,
  ctas: Array,
  image: ImageType,
  image2: ImageType,
  days: Array,
}

const Agenda2021 = ({
  title,
  text,
  editionBGColor,
  ctas,
  image,
  image2,
  days,
}: Props) => {

  const [bgmask, setBgmask] = useState()
  const [bgmask2, setBgmask2] = useState()

  useEffect(() => {
    const baseURL = window.location.origin
    setBgmask(baseURL + image.dir + image.name.normal + '.' + image.extension)
    setBgmask2(baseURL + image2.dir + image2.name.normal + '.' + image2.extension)
  }, [])

  const scrollTo = (anchor) => {
    const element = document.querySelector(`.${anchor}`)
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  return(
    <>
      <div className="relative col-span-4 row-start-2 px-6 py-4 m-auto font-mono text-center uppercase bg-left-top bg-cover agenda md:pt-12 md:pb-18 md:px-0 md:row-start-3 md:col-span-8 md:col-start-3">
        {bgmask &&
          <div className="absolute top-0 left-0 w-1/2 bg-center bg-cover" style={{
            height: '70vw',
            backgroundImage: `url(${bgmask})`,
          }}></div>
        }
        {bgmask2 &&
          <div className="absolute bottom-0 right-0 w-1/2 bg-center bg-cover" style={{
            height: '70vw',
            backgroundImage: `url(${bgmask2})`,
          }}></div>
        }
        <Appear>
          <h1 className="mx-auto mt-2 mb-8 font-mono text-4xl text-center md:mt-0 md:mb-4 md:text-4.5xl">{title}</h1>
        </Appear>
        <Appear>
          <HTMLText className="m-auto font-mono font-light leading-tight text-black text-normal md:max-w-44 md:text-xl" text={text} />
        </Appear>
        <Appear>
          <div className="flex flex-col items-center justify-center my-12 xs:flex-row">
            {ctas.map((cta,index) => (
              <Button key={index} className="mx-4 mb-8 sm:mb-0" title={cta.ctaTitle} callback={() => scrollTo(cta.ctaUrl)}/>
            ))}
          </div>
        </Appear>
          <div className="flex flex-col items-center justify-center my-12">
            {days.map((day, index) => (
              <Day key={index} titleDay={day.title} events={day.events} anchor={day.anchor}/>
            ))}
          </div>
      </div>
    </>
  )
}




export default Agenda2021