// @flow

import React from 'react'

type Props = {
  color: string,
  className?: string,
}

const SvgTFLogo = ({ color, className }: Props) => (
  <div className={className}>
    <svg width="33" height="18" viewBox="0 0 33 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L16.3914 16.3912L31.7826 1" stroke="#131313" strokeWidth="2"/>
    </svg>
  </div>
)

export default SvgTFLogo