// @flow

import React from 'react'
import AllItems from 'components/AllItems'

import type { Image as ImageType } from 'types/'

type SpeakerType = {
  name: string,
  role: string,
}

type ItemType = {
  type: string,
  href: string,
  title: string,
  description?: string,
  image: ImageType,
  speakers: Array<SpeakerType>
}

type Props = {
  type: string,
  imageBackground?: ImageType,
  title?: string,
  items: Array<ItemType>,
}

const AllArticles = ({
  imageBackground,
  title,
  items,
 }: Props) => {
  return (
    <div>
      <AllItems
        type="articles"
        imageBackground={imageBackground}
        title={title}
        items={items}
      />
    </div>
  )
}

export default AllArticles