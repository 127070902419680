// @flow

import React from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'
import Image from 'components/Image'
import GridItem from 'components/GridItem'
import type { Image as ImageType } from 'types/'

type SpeakerType = {
  name: string,
  role: string,
}

type ItemType = {
  type: string,
  href: string,
  title: string,
  description?: string,
  image: ImageType,
  speakers: Array<SpeakerType>
}

type Props = {
  type: string,
  imageBackground?: ImageType,
  title?: string,
  items: Array<ItemType>,
}

const AllItems = ({
  type,
  imageBackground,
  title,
  items,
 }: Props) => {
  const shouldUseFlex = items.length < 4

  return (
    <div id="all" className={`relative pb-24 ${type === "talks" ? "text-white bg-black pt-12 md:pt-32" : "text-black"}`}>
      <div className="absolute inset-0 flex items-center justify-end h-full overflow-hidden md:block md:pt-32">
        {imageBackground && (
          <Appear>
            <div className={`${type === "talks" ? "transform scale-150 md:scale-100" : ""}`} style={{ marginRight: "-75%" }}>
              <Image image={imageBackground} alt="" transparency={true} />
            </div>
          </Appear>
        )}
      </div>
      {title && (
        <Container gridType="grid" className="relative pt-2 pb-12 md:pb-24">
          <div className="col-span-4 md:col-span-6">
            <Appear>
              <h3 className="leading-none max-w-xs pr-8 md:pr-0 sm:max-w-full text-white text-3xl md:text-3.75 xl:text-3.75-vw">{title}</h3>
            </Appear>
          </div>
        </Container>
      )}
      <Container className="relative">
        <div className={`${shouldUseFlex ? "-mx-10px flex flex-col md:flex-row items-start justify-center" : "grid grid-cols-4 gap-5 md:grid-cols-12 relative"}`}>
          {items.map((item, index) => (
            <div
              key={index}
              className={`${shouldUseFlex ? "px-10px w-full md:w-1/3 xl:w-1/4" : "col-span-4 xl:col-span-3"}`}
            >
              <GridItem type={type} {...item} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default AllItems