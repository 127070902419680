// @flow

import React from 'react'
import type { Image as ImageType } from 'types/'
import Related from 'components/Related'

type SpeakerType = {
  name: string,
  role: string,
}

type ItemType = {
  type: string,
  cta: string,
  href: string,
  title: string,
  image: ImageType,
  speakers: Array<SpeakerType>
}

type Props = {
  title: string,
  cta: string,
  href: string,
  items: Array<ItemType>,
  imageBackground?: ImageType,
}

const RelatedArticles = ({
  title,
  items,
  cta,
  href,
  imageBackground,
}: Props) => {
  return (
    <Related
      type="articles"
      title={title}
      items={items}
      cta={cta}
      href={href}
      imageBackground={imageBackground}
    />
  )
}

export default RelatedArticles