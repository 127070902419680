// @flow

import React from 'react'
import type { Image as ImageType } from 'types/'
import ModuleIterator from 'components/ModuleIterator'
import Container from 'components/Container'
import DetailTalkSpeaker from 'components/DetailTalkSpeaker'
import SvgPinkShape from 'components/SvgPinkShape'
import Appear from 'components/Appear'

type NetworkType = {
  title: string,
  link: string,
  image: any,
}

type SourceItemsType = {
  title: string,
  link: string,
}

type SourceObject = {
  title: string,
  items: Array<SourceItemsType>,
}

type SpeakerType = {
  image: ImageType,
  name: string,
  bio: string,
  link: string,
  description: string,
  networks?: Array<NetworkType>,
  sources: SourceObject,
}

type Props = {
  children?: React$Node,
  speakers: Array<SpeakerType>,
}

const DetailTalkWrapper = ({ children, speakers }: Props) => {
  return (
    <div className="text-white bg-black">
      <Container gridType="grid">
        <div className="relative col-span-4 row-start-2 md:row-start-auto md:col-span-3">
          {speakers && speakers.map(speaker => (
            <React.Fragment key={speaker.name}>
              <DetailTalkSpeaker {...speaker} single={speakers.length === 1} />
            </React.Fragment>
          ))}
        </div>
        <div className="col-span-4 row-start-1 md:row-start-auto md:col-span-8 md:col-start-5">
          <div className="absolute top-0 right-0 items-center justify-end hidden md:flex" style={{ height: "66.666%" }}>
            <Appear>
              <SvgPinkShape />
            </Appear>
          </div>
          <ModuleIterator modules={children.props.modules} />
        </div>
      </Container>
      <Appear>
        <div className="relative w-full h-12 md:h-24" style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)" }} />
      </Appear>
    </div>
  )
}

export default DetailTalkWrapper