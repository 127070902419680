// @flow

import React from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'
import Image from 'components/Image'
import type { Image as ImageType } from 'types/'

type Props = {
  image: ImageType,
  text: string,
  inverted?: any,
}

const DetailInlineImageTalk = ({
  image,
  text,
  inverted,
 }: Props) => {
  return (
    <div className="bg-black">
      <Container gridType="subgrid" className="relative py-8 md:py-12">
        <div className={`${(inverted === 1) ? "col-span-4 md:col-end-9" : "col-span-4 md:col-start-1"}`}>
          <Appear>
            <Image image={image} alt={text} transparency={false} />
          </Appear>
        </div>
        <div className={`${`${(inverted === 1) ? "md:row-start-1 col-span-4 md:col-start-1" : "md:pl-5 col-span-4 md:col-end-9"}`} pr-4 md:pr-2 pt-3 md:pt-0 text-xl md:text-3xl leading-tight text-purple xl:text-3xl-vw`}>
          <Appear>
            <p className="break-words">{text}</p>
          </Appear>
        </div>
      </Container>
    </div>
  )
}

export default DetailInlineImageTalk