// @flow

import React from 'react'

type Props = {
  children?: React$Node,
  className?: string,
  gridType?: string,
}

const Container = ({ children, className, gridType }: Props) => {
  return (
    <div
      className={`
        w-full 
        ${gridType !== "subgrid" ? "px-4 mx-auto lg:px-0 md:px-12 lg:w-4/5" : ""}
        ${gridType === "subgrid" ? "grid grid-cols-4 gap-4 md:grid-cols-8 md:gap-5" : ""}
        ${gridType === "grid" ? "grid grid-cols-4 gap-5 md:grid-cols-12" : ""}  
        ${className || ""}
      `}
    >
      {children}
    </div>
  )
}

export default Container