// @flow

import React, { useRef, useEffect, useState } from 'react'
import Container from './Container'
import Appear from './Appear'
import SvgTypeformLogo2 from './SvgTypeformLogo2'
import SvgHeart from './SvgHeart'
import SvgFacebookLogo from "./SvgFacebookLogo"
import SvgTwitterLogo from "./SvgTwitterLogo"
import SvgInstagramLogo from "./SvgInstagramLogo"
import SvgYoutubeLogo from "./SvgYoutubeLogo"
import SvgLinkedInLogo from "./SvgLinkedInLogo"

type LinkType = {
  title: string,
  href: string,
}

type Props = {
  links: Array<LinkType>,
  literalWithLove: string,
  typeformUrl: string,
  bgcolor?: string,
  editionBGColor?: string,
  image: ImageType,
  fontMono?: Boolean,
}

const Footer = ({
  links,
  literalWithLove,
  typeformUrl,
  editionBGColor,
  edition,
  image,
  fontMono,
}: Props) => {
  let fontFamilyMono = fontMono
  let backToTopButton = false
  if (edition !== 'meaningful-2020') {
    backToTopButton = true
    if (!fontMono) {
      fontFamilyMono = true
    }
  }

  const hasURL = typeformUrl && typeformUrl !== "" && typeformUrl !== "#"
  const typeformRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [createWidget, setCreateWidget] = useState(false)

  const [bgmask, setBgmask] = useState()

  useEffect(() => {
    const baseURL = window.location.origin
    if (image) {
      setBgmask(baseURL + image.dir + image.name.normal + '.' + image.extension)
    }
  }, [])

  useEffect(() => {
    if (!process.env.browser && typeof window !== "undefined") {
      setLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (loaded) {
      const tag = document.createElement('script')
      tag.src = 'https://embed.typeform.com/embed.js'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      try {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      } catch (err) {
        console.warn('Footer: error while inserting script tag', err)
      } finally {
        let timeCounter = 0
        const counter = setInterval(() => {
          if (typeof window.typeformEmbed !== "undefined") {
            setCreateWidget(true)
            clearInterval(counter)
          }
          timeCounter += 100
          if (timeCounter > 10000) clearInterval(counter)
        }, 100)
      }
    }
  }, [loaded])

  useEffect(() => {
    if (createWidget && hasURL) {
      if (typeof window.typeformEmbed !== "undefined") {
        window.typeformEmbed.makeWidget(typeformRef.current, `https://form.typeform.com/to/${typeformUrl}`, {
          hideFooter: true,
          hideHeaders: true,
          opacity: 100,
        })
      } else console.warn('Footer: either window.typeformEmbed is undefined or typeformUrl is incorrect')
    }
  }, [createWidget])

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    window.location.hash = ''
  }

   return (
    <div className={`overflow-hidden bg-cover bg-left-top relative text-sm text-black ${hasURL ? "" : "mt-12"}`}>
      <div className="absolute top-0 w-full bg-left-top bg-cover -z-10" style={{minHeight: '63vw', backgroundImage: `url(${bgmask})` }}></div>
      {hasURL && (
        <Container className="pt-24 lg:max-w-full">
          <Appear>
            <div className="w-full mx-auto mb-2 overflow-hidden border rounded-md md:w-2/3 border-gray">
              <div ref={typeformRef} style={{ width: "100%", height: "500px" }} />
            </div>
          </Appear>
        </Container>
      )}
      {backToTopButton && 
        <div className="flex items-center justify-center mt-20 mb-8" >
          <a className="px-6 pt-3 pb-3 m-auto font-mono text-base font-normal text-center bg-white rounded-full cursor-pointer lg:px-5 xl:px-8" onClick={() => backToTop()}>BACK TO TOP</a>
        </div>
      }
      <Container className="flex flex-col items-center justify-center uppercase lg:w-9/10 bordert-1 border-gray lg:p-10 lg:flex-row lg:items-center lg:justify-between">
        <div className="my-6 lg:my-0">
          <Appear>
            <SvgTypeformLogo2 color="black" />
          </Appear>
        </div>
       
        <div className="flex flex-col items-center justify-center lg:flex-row lg:items-center lg:justify-end">
          <div className={`flex flex-col-reverse items-center justify-center lg:flex-row`}>
            <Appear>
              <a href="https://www.typeform.com/" className={`${fontFamilyMono? 'font-mono uppercase' : ''}`}>© Typeform</a>
            </Appear>
            <Appear>
              <div className={`mb-4 lg:mb-0 text-center ${fontFamilyMono? 'font-mono uppercase' : ''}`}>
                {links.map((link, index) => (
                  <React.Fragment key={index}>
                    <span className={`mx-2 ${index === 0 ? "hidden lg:inline" : ""}`}>|</span>
                    <a href={link.href} className="underline hover:no-underline">
                      {link.title}
                    </a>
                  </React.Fragment>
                ))}
              </div>
            </Appear>
          </div>
          <div className="flex flex-row items-center justify-between lg:justify-center lg:mt-0 lg:ml-4 lg:mr-1 lg:pb-1">
            <Appear className="mx-2 transform scale-80">
              <a className="mx-2 transform scale-80" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/typeform/"><SvgFacebookLogo/></a>
            </Appear>
            <Appear className="mx-1 transform scale-80">
              <a className="mx-1 transform scale-80" target="_blank" rel="noopener noreferrer" href="https://twitter.com/typeform/"><SvgTwitterLogo/></a>
            </Appear>
            <Appear className="mx-1 transform scale-80">
              <a className="mx-1 transform scale-80" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/typeform/"><SvgInstagramLogo/></a>
            </Appear>
            <Appear className="mx-1 transform scale-80">
              <a className="mx-1 transform scale-80" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC2nRv6J0FsJVePJTLhUDKgg"><SvgYoutubeLogo/></a>
            </Appear>
            <Appear className="mx-2 mb-1 transform scale-88">
              <a className="mx-2 mb-1 transform scale-88" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/typeform-"><SvgLinkedInLogo/></a>
            </Appear>
        </div>
          <div className={`my-8 lg:my-0 ${fontFamilyMono? 'font-mono uppercase text-pink' : 'text-purple'}`}>
            <Appear className="flex items-center justify-start ">
              <span className="mr-2 lg:mx-2">
                <SvgHeart />
              </span>
              {literalWithLove}
            </Appear>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer