// @flow

import React from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'
import ReactMarkdownWithHtml from 'react-markdown/with-html'

type Props = {
  pageName: string,
  text: string,
}

const DetailTextBlock = ({
  pageName,
  text,
 }: Props) => {
  return (
    <div className={`${pageName === "talkDetail" ? "bg-black text-white" : ""}`}>
      <Container gridType={`${pageName === "talkDetail" ? "subgrid" : "grid"}`} className="relative pb-8">
        <div className={`${pageName === "talkDetail" ? "col-start-1 col-span-7" : "col-span-4 md:col-start-4 md:col-span-9"}`}>
          <Appear>
            <ReactMarkdownWithHtml
              className="text-sm leading-tight md:text-2xl xl:text-2xl-vw markdown"
              allowDangerousHtml={true}
            >
              {text}
            </ReactMarkdownWithHtml>
          </Appear>
        </div>
      </Container>
    </div>
  )
}

export default DetailTextBlock