// @flow

import React from 'react'
import Appear from 'components/Appear'
import Container from 'components/Container'
import Image from 'components/Image'
import GridItem from 'components/GridItem'
import type { Image as ImageType } from 'types/'
import { Link } from 'gatsby'

type SpeakerType = {
  name: string,
  role: string,
}

type ItemType = {
  type: string,
  cta: string,
  href: string,
  title: string,
  image: ImageType,
  speakers: Array<SpeakerType>
}

type Props = {
  type: string,
  title: string,
  cta: string,
  href: string,
  items: Array<ItemType>,
  imageBackground?: ImageType,
}

const Related = ({
  type,
  title,
  items,
  cta,
  href,
  imageBackground,
 }: Props) => {
  const handleHref = () => {
    if (type === "talks") {
      return `${href}#all`
    }
    return href
  }

  const shouldUseFlex = items.length < 4

  return (
    <div className={`relative pt-12 md:pt-10 pb-24 ${imageBackground ? "text-white bg-purple" : "text-black bg-white"}`}>
      <div className="absolute inset-0">
        {imageBackground && (
          <div className="flex items-end justify-center h-full overflow-hidden md:pt-32">
            <Appear>
              <Image image={imageBackground} alt="" className="h-auto w-300vw md:w-auto" transparency={true} />
            </Appear>
          </div>
        )}
      </div>
      <Container gridType="grid" className={`relative pb-12 md:pb-20 ${imageBackground ? "pt-2 md:pt-10" : "pt-2 md:pt-4"}`}>
        <div className="col-span-4 md:col-span-6 md:col-start-4 ">
          <Appear>
            <h3 className={`leading-none ${type === "talks" ? "text-left md:text-center" : "text-center"} text-3xl md:text-2.5 xl:text-2.5-vw`}>{title}</h3>
          </Appear>
        </div>
      </Container>
      <Container
        gridType={`${shouldUseFlex ? "" : "grid"}`}
        className="relative"
      >
        <div className={`${shouldUseFlex ? "flex flex-col md:flex-row items-start justify-center" : ""}`}>
          {items.map((item, index) => (
            <div
              key={index}
              className={`${shouldUseFlex ? "px-10px w-full md:w-1/3 xl:w-1/4" : "col-span-4 xl:col-span-3"}`}
            >
              <GridItem type={type} {...item} />
            </div>
          ))}
        </div>
      </Container>
      <Container gridType="grid" className="relative">
        <div className="flex items-center justify-center col-span-4 pt-1 mt-12 md:col-span-6 md:col-start-4">
          <Appear>
            <Link to={handleHref()} className="text-xl underline hover:no-underline xl:text-xl-vw">
              {cta}
            </Link>
          </Appear>
        </div>
      </Container>
    </div>
  )
}

export default Related