// @flow

import React, { useState, useEffect } from 'react'
import type { Image as ImageType } from 'types/'
import Appear from 'components/Appear'
import Container from 'components/Container'
import HTMLText from 'components/HTMLText'
import Menu2021 from 'components/Menu2021'
import SvgTFLogo from './SvgTFLogo'

type Props = {
  backgroundSimpleLandingXL: ImageType,
  backgroundSimpleLandingXS: ImageType,
  event?: string,
  title?: string,
  text?: string,
  subtitle?: string,
  ctaTitle?: string,
  ctaUrl?: string,
  editionBGColor?: string,
  image: ImageType,
  menuItems?: Array,
}

const SimpleLanding = ({
  backgroundSimpleLandingXL,
  backgroundSimpleLandingXS,
  event,
  title,
  text,
  subtitle,
  ctaTitle,
  ctaUrl,
  editionBGColor = '',
  image,
  menuItems,
}: Props) => {
  const [bgmask, setBgmask] = useState()

  useEffect(() => {
    const scrollTo = (anchor) => {
      const element = document.querySelector(`.${anchor}`)
      if (parent === 'video') window.location.href = `/2021-full#!${anchor}`
      if (element) {
        const { top } = element.getBoundingClientRect()
        const y = top + window.pageYOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }

    const baseURL = window.location.origin
    setBgmask(baseURL + image.dir + image.name.normal + '.' + image.extension)

    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      setTimeout(() => {
        scrollTo(parts[1])
      }, 1000)
    }
  }, [])

  return (
    <>
      <div className={`relative h-100-vh text-black bg-center bg-cover`} style={{ backgroundImage: `url(${bgmask})` }}>
        <Menu2021 event={(event)} menuItems={(menuItems)} editionBGColor={editionBGColor} />

        {(title || text || subtitle) && (
          <Container gridType="grid" className={`absolute top-0 bottom-0 m-auto h-20-rem lg:w-full`}>
            <div key="title-area" className="col-span-4 col-start-1 row-sart-1 md:row-start-2 md:col-span-8 md:col-start-3">
              <Appear>
                <h1 className="mx-auto mt-1 font-mono text-4xl leading-none text-center xs:max-w-80vw sm:max-w-60vw md:max-w-500 md:mt-0 md:text-4xl">{title}</h1>
              </Appear>
            </div>
            {(text || subtitle ) && (
              <div key="main-paragraph" className="col-span-4 row-start-2 font-mono text-center md:row-start-3 md:col-span-8 md:col-start-3">
                <Appear>
                  <HTMLText className="max-w-sm m-auto text-xl leading-tight text-black md:max-w-lg md:text-xl xl:max-w-60vw" text={subtitle} />
                </Appear>
                <Appear>
                  <HTMLText className="m-auto mt-6 mb-16 text-lg leading-snug md:mb-12 md:text-lg markdown" text={text} />
                </Appear>
                {ctaTitle &&
                  <Appear>
                    <a className="px-6 pt-5 pb-4 m-auto text-2xl bg-white rounded-full md:text-sm xs:text-base" href={ctaUrl}>{ctaTitle}</a>
                  </Appear>
                }
              </div>
            )}
          </Container>
        )}
        <div className="absolute bottom-0 left-0 right-0 pt-4 sm:pt-12">
          <Appear className="flex items-center">
            <SvgTFLogo className="mx-auto mb-12-vh sm:mb-24" />
          </Appear>
        </div>
      </div>
    </>
  )
}

export default SimpleLanding