// @flow

import React, { useState, useEffect } from 'react'
import Appear from 'components/Appear'
import HTMLText from 'components/HTMLText'
import Image from 'components/Image'

type Props = {
  event?: string,
  title?: string,
  text?: string,
  titleAgenda?: string,
  finalTitle?: string,
  bgcolor?: string,
  image: ImageType,
}

const Agenda = ({
  title,
  text,
  titleAgenda,
  finalTitle,
  items,
  bgcolor,
  image,
}: Props) => {

  const [bgmask, setBgmask] = useState()

  useEffect(() => {
    const baseURL = window.location.origin
    setBgmask(baseURL + image.dir + image.name.normal + '.' + image.extension)
  }, [])

  return (
    <>
      <div key="main-paragraph" className="relative col-span-4 row-start-2 px-6 m-auto font-mono text-center uppercase bg-center bg-cover md:pt-12 md:pb-34px md:row-start-3 md:col-span-8 md:col-start-3"
        style={{backgroundColor: `#${bgcolor}` }}
      >
        <div className="absolute top-0 right-0 w-full h-full bg-center bg-cover" style={{
          minHeight: '900px',
          backgroundImage: `url(${bgmask})`,
          maxWidth: '1400px',
        }}></div>
        <div className="flex flex-col items-center justify-center max-w-full m-auto">
          <Appear>
            <h1 className="mx-auto mt-2 mb-8 font-mono text-2xl text-center md:mt-0 md:mb-4 md:text-4.5xl">{title}</h1>
          </Appear>
          <Appear>
            <HTMLText className="m-auto font-mono leading-tight text-black text-normal md:max-w-44 md:text-xl" text={text} />
          </Appear>
          <Appear>
            <HTMLText className="m-auto mt-6 mb-6 font-medium leading-snug text-normal md:mb-16 md:text-lg markdown" text={titleAgenda} />
          </Appear>
          <div className="grid md:grid-cols-3 gap-7.5 md:w-9/10" style={{	gridAutoRows: 'minmax(0, 1fr)' }}>
            {items && items.map((item, itemIndex) => {
              return <Appear key={itemIndex} className="mx-6px">
                <div className="flex h-full bg-white py-30px px-30px rounded-xl md:px-6 md:py-4">
                  <HTMLText className="leading-normal text-left markdown" text={item.title} />
                </div>
              </Appear>
            })}
          </div>
          <Appear>
            <HTMLText className="m-auto mt-20 font-medium leading-snug text-normal md:mb-0 md:text-lg markdown" text={finalTitle} />
          </Appear>
        </div>
      </div>
    </>
  )
}

export default Agenda