// @flow
import React from 'react'
import type { Module } from 'types/'

const components = {}

function importAll(r) {
  r.keys().forEach(key => {
    const filename = key.match(/([a-zA-Z0-9]+)\.js$/)
    if (filename) {
      components[filename[1]] = r(key).default
    }
  })
}

importAll(require.context('components', true))

const ModuleIterator = ({ modules, edition, bgcolor }: { modules: Array<Module> }) => {
  return (
    <>
      {modules.map((m: Module, index: number) => {
        const Component = components[m.name]
        if (typeof Component === 'undefined') {
          console.log('%c Var ', 'color: black; background-color: #00C6B9', `Component ${m.name} not found`)
          return false
        }
        return (
          <Component {...m.props} edition={edition} editionBGColor={bgcolor} key={index}>
            {m.structure && <ModuleIterator modules={m.structure} />}
          </Component>
        )
      })}
    </>
  )
}

export default ModuleIterator
