// @flow

import React from 'react'

type Props = {
  color: string,
}

const SvgMeaningfulLogo = ({ color }: Props) => (
  <svg width="100" height="21" xmlns="http://www.w3.org/2000/svg"><g fill={color} fillRule="nonzero"><path d="M0 0h4.316l4.878 12.92L14.073 0h4.316v20.695h-3.843V7.923l-3.962 10.88h-2.78l-3.96-10.88v12.772H0zM63.565 8.314v3.88H38.25v-3.88zM90.392 3.548h-5.765V0H100v3.548h-5.765v17.147h-3.843z"/></g></svg>
)

export default SvgMeaningfulLogo