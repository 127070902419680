// @flow

import React, { useState, useLayoutEffect } from 'react'
import type { Image as ImageType } from 'types/'
import { Link } from 'gatsby'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import Appear from 'components/Appear'
import Container from 'components/Container'
import SvgMeaninfulLogo from './SvgMeaninfulLogo'
import SvgTypeformLogo from './SvgTypeformLogo'
import SvgArrowDown from './SvgArrowDown'
import Image from 'components/Image'


type Props = {
  pageName: string,
  imageBackground: ImageType,
  arrow?: boolean,
  smallTitle?: string,
  title?: string,
  text?: string,
  subtitle?: string,
}

const Hero = ({
  pageName,
  imageBackground,
  arrow,
  smallTitle,
  title,
  text,
  subtitle,
 }: Props) => {
  
   
  const [color, setColor] = useState("")

  useLayoutEffect(() => {
    const color = handleLogoStyleByPageName()
    setColor(color)
  }, [])

  const handleImageStyleByPageName = () => {
    switch(pageName) {
      default:
      case "home": return "md:-mt-24 mt-32"
      case "articles": return "transform scale-150 md:scale-100 -mt-24 md:-mt-16"
      case "articleDetail": return "w-4/5 md:w-1/2 float-right"
      case "talkDetail": return "transform scale-150 md:scale-100 w-full md:w-1/3 float-right"
    }
  }

  const handleWrapperStyleByPageName = () => {
    switch(pageName) {
      default:
      case "home": return "pb-16 md:pb-48 text-white bg-purple"
      case "articles": return "pb-24 text-black"
      case "articleDetail": return "text-black"
      case "talkDetail": return "text-white bg-black"
    }
  }

  const handleLogoStyleByPageName = () => {
    switch(pageName) {
      default:
      case "talkDetail":
      case "home": return "#fff"
      case "articles":
      case "articleDetail": return "#000"
    }
  }

  return (
    <>
      <div className="absolute inset-x-0 bottom-0 z-30 hidden pointer-events-none md:block md:pt-12 md:bottom-auto md:top-0">
        <Container gridType="grid">
          <div className="flex items-center justify-end md:col-span-3 md:col-end-13">
            <div className="w-24 m-5 pointer-events-auto md:m-0 md:w-auto">
              <Appear>
                <a
                  href="https://www.typeform.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <SvgTypeformLogo color={color} />
                </a>
              </Appear>
            </div>
          </div>  
        </Container>
      </div>
      <div className={`relative ${handleWrapperStyleByPageName()}`}>
        {imageBackground && (
          <div className={`${pageName === "talkDetail" ? "fixed bottom-0" : "absolute"} inset-x-0 top-0 z-0 w-full pl-4 overflow-hidden md:left-auto md:pt-0 md:block md:pl-32 patata`} style={{ maxWidth: "1440px" }}>
            <Image
              image={imageBackground}
              alt=""
              className={`${handleImageStyleByPageName()}`}
              transparency={false}
              force={true}
            />
          </div>
        )}
        <Container gridType="grid" className={`z-10 relative pt-12 ${pageName === "home" ? "pb-64" : "pb-24 md:pb-32"}`}>
          <div className="col-span-3 pr-4 md:pr-0">
            <Appear>
              <Link to="/">
                <SvgMeaninfulLogo color={color} />
              </Link>
            </Appear>
          </div>
        </Container>
        {(arrow || smallTitle || title || text || subtitle) && (
          <Container gridType="grid" className={`relative ${pageName === "home" ? "pb-4" : ""}`}>
            {arrow && (
              <div
                className="flex items-start justify-end col-span-1 row-start-1 mt-6 md:row-start-2 md:justify-start md:w-1/2 md:mt-4"
                style={{ minWidth: "2.5rem" }}
              >
                <Appear>
                  <SvgArrowDown color={color} />
                </Appear>
              </div>
            )
            }
            <div className="hidden col-span-3 col-start-2 row-start-1 md:block">
              <Appear>
                <span className="text-sm font-bold md:text-xl xl:text-xl-vw">{smallTitle}</span>
              </Appear>
            </div>
            <div key="title-area" className="col-span-3 col-start-2 row-sart-1 md:row-start-2 md:col-span-7 md:col-start-2">
              <div className="md:hidden">
                <Appear>
                  <span className="text-sm font-bold md:text-xl xl:text-xl-vw">{smallTitle}</span>
                </Appear>
              </div>
              <Appear>
                <h1 className="max-w-xs mt-1 text-3xl leading-none sm:max-w-full md:mt-0 md:text-5xl xl:text-5xl-vw">{title}</h1>
              </Appear>
            </div>
            {(text || subtitle) && (
              <div key="main-paragraph" className="col-span-4 row-start-2 md:row-start-3 md:col-span-8 md:col-start-4">
                <Appear>
                  <ReactMarkdownWithHtml className="mt-8 mb-12 text-sm leading-snug md:mb-16 md:text-2xl xl:text-2xl-vw markdown">
                    {text}
                  </ReactMarkdownWithHtml>
                </Appear>
                <Appear>
                  <h4 className="max-w-xs md:max-w-full text-xl md:text-2.5 xl:text-2.5-vw text-black leading-tight">{subtitle}</h4>
                </Appear>
              </div>
            )}
          </Container>
        )}
      </div>
    </>
  )
}

export default Hero