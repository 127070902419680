import React from 'react'
import Event from './Event'
import Container from 'components/Container'

type Props = {
  titleDay: String,
  events: Array,
  anchor: String,
}

const Day = ({
  titleDay,
  events,
  anchor,
}: Props) =>{

  return(
    <>
    <div className={`mx-auto lg:px-0 md:px-12 lg:w-4/5 lg:max-w-full ${anchor}`}>
        <div className="flex flex-col items-center justify-center">
          <h1 className="z-20 mt-8 md:text-3xl">{titleDay}</h1>
        </div>
        <div className="grid grid-cols-3 gap-7.5 mt-6 mb-10 md:grid-cols-12">
          {events.map((event, index) => (
            <Event className="col-span-3 min-h-18 h-310 md:col-span-4" key={index} text={event.text} datetime={event.datetime} title={event.title}/>
          ))}
        </div>
      </div>
    </>
  )

}

export default Day